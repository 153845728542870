/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        /*
         * Primary navigation
         */
        var navParents = document.querySelectorAll( '.menu-item-has-children > a' );

        function showHideChildren(e) {

          if (window.matchMedia('(max-width: 999px)').matches) {
            e.preventDefault();

            className = 'is-open';

            // toggle display class
            if (this.classList) {
              this.classList.toggle(className);
            } else {
              var classes = this.className.split(' ');
              var existingIndex = classes.indexOf(className);

              if (existingIndex >= 0)
                classes.splice(existingIndex, 1);
              else
                classes.push(className);

              this.className = classes.join(' ');
            }
          }
        }


        // listen for click of nav item
        for ( i = 0; i < navParents.length; i++ ) {
          navParents[i].addEventListener( 'click', showHideChildren );
        }


        /*
         * Toggle display of small-screen navigation
         */
        var toggleButton = document.querySelector( '.c-siteheader__toggle-button' );
        var menu = document.querySelector( '.c-siteheader__nav' );

        function toggleMenu() {

          className = 'is-open';

          // toggle display class
          if (menu.classList) {
            menu.classList.toggle(className);
          } else {
            var classes = menu.className.split(' ');
            var existingIndex = classes.indexOf(className);

            if (existingIndex >= 0)
              classes.splice(existingIndex, 1);
            else
              classes.push(className);

            menu.className = classes.join(' ');
          }
        }

        // Listen for click of toggle button
        toggleButton.addEventListener( 'click', toggleMenu );





        /*
         * Fixed navigation on scroll
         */

        var body = document.querySelector( 'body' );
        var header = document.querySelector( '.c-siteheader-container' );
        var sticky = header.offsetTop;

        // Toggle scroll class
        function toggleScroll() {
          if (window.pageYOffset > sticky) {
            body.classList.add('is-scrolling');
          } else {
            body.classList.remove('is-scrolling');
          }
        }

        window.onscroll = function() { toggleScroll(); };





        /*
         * Detect objectFit support
         */
        if('objectFit' in document.documentElement.style === false) {

          // assign HTMLCollection with parents of images with objectFit to variable
          var images = document.querySelectorAll( '.c-feature__image' );

          // // Loop through HTMLCollection
          for ( i = 0; i < images.length; i++ ) {

            // Asign image source to variable
            if ( images[i].getElementsByTagName('img').length > 0 ) {
              var imageSource = images[i].querySelector('img').src;

              // Hide image
              images[i].querySelector('img').style.display = 'none';

              // Add background-size: cover
              images[i].style.backgroundSize = 'cover';

              // Add background-image: and put image source here
              images[i].style.backgroundImage = 'url(' + imageSource + ')';

              // Add background-position: center center
              images[i].style.backgroundPosition = 'center center';
            }

          }
        }





        /*
         * Add customizable select box
         * https://select2.org/
         */
        $('.c-filter__select').select2();






        // Page scrolling to in-page anchors
        $('.c-wysiwyg a, .c-article__footnotes a').on('click',function (event) {
          console.log('hello');

          href = typeof(href) === "string" ? href : $(this).attr("href");

          // If href missing, ignore
          if(!href) {
            return;
          }

          var target = this.hash;
          $target = $(target);

          $('html, body').stop().animate({
            'scrollTop': $target.offset().top - 120
          }, 200);                
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        /*
         * Filter posts
         */
        var triggers = document.querySelectorAll( '.c-filter__trigger' );
        var items = document.querySelectorAll( '.o-isotope__item' );

        function filterItems(e) {
          e.preventDefault();

          var filter = this.getAttribute('data-filter');
          var filterClass = filter.substr(1);

          // toggle show-hide
          for ( i = 0;  i < items.length;  i++) {
            if ( ! items[i].classList.contains(filterClass) && filterClass != 'all' ) {
              items[i].classList.add('is-hidden');
            } else {
              items[i].classList.remove('is-hidden');
            }
          }
        }

        // listen for click of filter option
        for ( i = 0; i < triggers.length; i++ ) {
          triggers[i].addEventListener( 'click', filterItems );
        }





        /*
         * Populate Twitter feed
         */
        var configList = {
          'profile': {'screenName': 'FrontierEcon'},
          'domId': 'twitter-feed',
          'maxTweets': 3,
          'showUser': false,
          'showRetweet': false,
          'showImages': false,
          'showInteraction': true,
          'showPermalinks': false,
          'showTime': true,
          'lang': 'en',
          'enableLinks': true
        };
        twitterFetcher.fetch(configList);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
